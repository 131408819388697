// MUI
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/system';

// Pages
import { Filter, PresetFilter } from '../../pages/dataPage';

// Internal
import CustomExportButton from './customExport';
import PresetFilters from '../presetFilters'
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Autorenew } from '@mui/icons-material';

interface CustomToolbarProps {
  filters: Filter[]
  setFilters: Function
  applyFilters: Function
  updateFilterConfiguration: Function
  openPresetFilterSaveDialogue: Function
  openImportDisplay: Function
  presetFilterList: PresetFilter[]
  modelMetadata: any
  handleGoogleSheetsExport: Function
  loading: boolean
  deleteFilterConfiguration: Function
  setImportMenuOpen: Function
}

const CustomToolbar = ({
  filters,
  setFilters,
  applyFilters,
  updateFilterConfiguration,
  openPresetFilterSaveDialogue,
  openImportDisplay,
  presetFilterList,
  modelMetadata,
  handleGoogleSheetsExport,
  loading,
  deleteFilterConfiguration,
  setImportMenuOpen,
  ...props
}: CustomToolbarProps) => {

  return (
    <GridToolbarContainer
      style={{
        width: "100%",
        display: "flex",
        justifyContent: 'space-between'
      }}
    >
      <Box>
        <GridToolbarColumnsButton nonce={''} onResize={() => null} onResizeCapture={() => null} disabled={loading}/>
        <GridToolbarDensitySelector nonce={''} onResize={() => null} onResizeCapture={() => null} disabled={loading}/>
        <CustomExportButton
          handleGoogleSheetsExport={handleGoogleSheetsExport}
          loading={loading}
          setImportMenuOpen={setImportMenuOpen}
         {...props}
        />
        <span>
          <GridToolbarFilterButton
            nonce={''}
            onResize={() => null}
            onResizeCapture={() => null}
            componentsProps={{
              button: {
                disabled: loading
              }
            }}
          />
        </span>
        <Tooltip
          title={"Check Import Status"}
        >
          <span>
            <Button
              startIcon={<Autorenew />}
              onClick={() => openImportDisplay()}
              disabled={loading}
            >
              Progress
            </Button>
          </span>
        </Tooltip>
        <PresetFilters
          filters={filters}
          updateFilterConfiguration={updateFilterConfiguration}
          openPresetFilterSaveDialogue={openPresetFilterSaveDialogue}
          presetFilterList={presetFilterList}
          loading={loading}
          deleteFilterConfiguration={deleteFilterConfiguration}
        />
      </Box>
      <Box>
        <GridToolbarQuickFilter 
          disabled={loading}
          debounce={500}
        />
      </Box>
    </GridToolbarContainer>
  );
}

export default CustomToolbar
