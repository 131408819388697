import axios from "axios";
import {BASEROCK_API_URL} from "../settings";

interface SendRequest {
  requestType: "post" | "get" | "put" | "options" | "delete" | "patch";
  requestHeader?: string;
  requestData?: any;
  requestParams?: any;
  endpoint?: any;
  headers?: any;
}


const sendRequest = async ({
  requestType,
  requestHeader,
  requestData,
  requestParams,
  headers,
}: SendRequest) => {
  const credential = localStorage.getItem(
    `${process.env.REACT_APP_GCP_PROJECT}_access`
  );
  const defaultHeaders = {
    Accept: "application/json",
  };

  // Construct the full URL
  const fullUrl = `${BASEROCK_API_URL}/${requestHeader}`;
  console.log(`Sending request to ${fullUrl}`); 

  try {
    const data = await axios({
      method: requestType,
      url: `${BASEROCK_API_URL}/${requestHeader}`,
      data: requestData,
      params: requestParams,
      headers: headers
        ? {...headers, ...defaultHeaders}
        : {Authorization: `Bearer ${credential}`, ...defaultHeaders},
    });
    
    return data.data;

  } catch (err) {
    console.log(err);
    return false;
  }
};

export default sendRequest;
