
import React, { MouseEvent, useState, useEffect } from "react";
import { Switch, FormControlLabel } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  IconButton,
  ListItem,
  ListItemButton,
  Menu,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import SaveIcon from "@mui/icons-material/Save";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useGridApiContext, useGridApiRef } from "@mui/x-data-grid-pro";
import { Delete } from "@mui/icons-material";
import Button from "@mui/material/Button";

import { PresetFilter } from "../pages/dataPage";

interface PresetFiltersProps {
  updateFilterConfiguration: Function;
  presetFilterList: any[];
  filters: any[];
  openPresetFilterSaveDialogue: Function;
  loading: boolean;
  deleteFilterConfiguration: Function;
}

const PresetFilters = ({
  updateFilterConfiguration,
  presetFilterList,
  filters,
  openPresetFilterSaveDialogue,
  loading,
  deleteFilterConfiguration,
}: PresetFiltersProps) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [modelName, setModelName] = useState<string>(""); // <-- Add modelName state here
  const selectPresetDialogueOpen = Boolean(anchorElement);
  const [columnOrder, setColumnOrder] = useState<string[]>([]); // Initialize as empty array
  const gridApi = useGridApiContext();
  const { enqueueSnackbar } = useSnackbar();
  const apiRef = useGridApiRef();
  const [isPrivateUpdate, setIsPrivateUpdate] = useState<boolean>(true); // Default to private

  // Store the original column order when the component mounts
  useEffect(() => {
    const fetchColumnOrder = () => {
      const allColumns = gridApi.current.getAllColumns();
      if (allColumns) {
        const order = allColumns.map((col) => col.field);
        setColumnOrder(order);
      }
    };

    fetchColumnOrder();

    // Optionally, add an event listener if columns can be reordered dynamically
    // to keep the original order updated. This depends on your specific use case.
  }, [gridApi]);

  const applyFilter = (model: any) => {
    const { filters, columns, pinnedColumns } = JSON.parse(model.filter_string);

    // Apply new filters
    setSelectedFilter(model.name);
    setModelName(model.name); // <-- Set the modelName state when applying the filter
    gridApi.current.setFilterModel({ items: filters });

    // Apply column ordering and visibility
    const allColumns = gridApi.current.getAllColumns();
    allColumns?.forEach(({ field }: { field: string }) => {
      if (columns.includes(field)) {
        gridApi.current.setColumnVisibility(field, true);
        gridApi.current.setColumnIndex(field, columns.indexOf(field));
      } else {
        gridApi.current.setColumnVisibility(field, false);
      }
    });

    // Apply pinned columns if any
    if (pinnedColumns) {
      gridApi.current.setPinnedColumns(pinnedColumns);
    }

    // Update the toggle switch based on the filter's private status
    setIsPrivateUpdate(model.private);

    enqueueSnackbar(`Filter "${model.name}" configuration applied!`, {
      variant: "success",
    });
    setAnchorElement(null);
  };

  const resetColumnOrder = () => {
    if (columnOrder.length > 0) {
      columnOrder.forEach((field, index) => {
        gridApi.current.setColumnIndex(field, index);
        gridApi.current.setColumnVisibility(field, true); // Ensure all columns are visible
      });
    }
  };

  return (
    <Box>
      {/* Tooltip around the select preset filter button */}
      <Tooltip
        title={
          presetFilterList.length < 1
            ? "No preset filters found"
            : "Select preset filter"
        }
      >
        <span>
          <IconButton
            onClick={(event: MouseEvent<HTMLElement>) =>
              setAnchorElement(event.currentTarget)
            }
            disabled={presetFilterList.length < 1 || loading}
          >
            <ListAltIcon
              color={
                presetFilterList.length < 1 || loading ? "disabled" : "success"
              }
            />
          </IconButton>
        </span>
      </Tooltip>

      <Menu
        style={{ maxHeight: 400 }}
        open={selectPresetDialogueOpen}
        anchorEl={anchorElement}
        onClose={() => setAnchorElement(null)}
      >
        {presetFilterList.length > 0 &&
          presetFilterList.map((presetFilter: PresetFilter, index: number) => (
            <ListItem
              disableGutters
              key={presetFilter.name} // Use unique identifier
              style={{
                backgroundColor:
                  selectedFilter === presetFilter.name
                    ? "lightblue"
                    : "inherit",
              }}
              secondaryAction={
                <Tooltip title="Delete Filter">
                  <IconButton
                    onClick={() => deleteFilterConfiguration(presetFilter.name)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemButton onClick={() => applyFilter(presetFilter)}>
                {presetFilter.name}
              </ListItemButton>
            </ListItem>
          ))}
      </Menu>

      {/* Tooltip around the Save Filter Configuration button */}
      <Tooltip
        title={
          filters.length < 1 || loading
            ? "No configuration found"
            : "Save Filter Configuration"
        }
      >
        <span>
          <IconButton
            onClick={() => {
              const currentVisibleColumns = gridApi.current
                .getVisibleColumns()
                .map(({ field }: { field: string }) => field);
              openPresetFilterSaveDialogue({ columns: currentVisibleColumns });
            }}
            disabled={filters.length < 1 || loading}
          >
            <SaveIcon
              color={
                filters.length < 1 || loading || modelName
                  ? "disabled"
                  : "success"
              }
            />
          </IconButton>
        </span>
      </Tooltip>

      {modelName && (
        <Box
          mt={2}
          sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
        >
          <span>Current Filter: {modelName}</span>

          {/* Tooltip around Remove Filter button */}
          <Tooltip title="Remove Filter">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setModelName(""); // Clear the model name
                gridApi.current.setFilterModel({ items: [] }); // Clear the filters in the grid
                enqueueSnackbar("Filter removed!", { variant: "info" });
                resetColumnOrder(); // Reset column order to original
              }}
              sx={{ ml: 2 }}
            >
              Remove Filter
            </Button>
          </Tooltip>

          {/* Tooltip around Update Filter button */}
          <Tooltip title="Update Filter">
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                await updateFilterConfiguration(modelName, isPrivateUpdate);
              }}
              sx={{ ml: 2 }}
            >
              Update
            </Button>
          </Tooltip>

          <FormControlLabel
            control={
              <Switch
                checked={!isPrivateUpdate}
                onChange={(e) => setIsPrivateUpdate(!e.target.checked)}
                name="publicToggle"
                color="primary"
                sx={{ ml: 2 }}
              />
            }
            label={isPrivateUpdate ? "Private" : "Public"}
          />
        </Box>
      )}
    </Box>
  );
};

export default PresetFilters;
