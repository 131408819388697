// External
import { useState } from 'react'

// MUI Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, FormControlLabel } from '@mui/material';

interface SavePresetFilterDialogProps {
  open: boolean,
  saveFilterConfiguration: Function,
  closeFilterSaveDialogue: Function
}

const SavePresetFilterDialog = ({
  open,
  saveFilterConfiguration,
  closeFilterSaveDialogue,
}: SavePresetFilterDialogProps) => {

  const [filterName, setFilterName] = useState('')
  const [isPrivate, setIsPrivate] = useState(true)

  return (
    <Dialog open={open} onClose={() => closeFilterSaveDialogue()}>
      <DialogTitle>Save Filter Set</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To save the current filter set, please enter a unique filter set name.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Filter Set Name"
          type="text"
          fullWidth
          variant="standard"
          onKeyDown={({ key }) => key === "Enter" && saveFilterConfiguration(filterName)}
          onChange={(event: any) => setFilterName(event.target.value)}
        />
      <FormControlLabel label='Private' control={
        <Checkbox 
          checked={isPrivate}
          onChange={() => setIsPrivate(!isPrivate)}
        />
      }/>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeFilterSaveDialogue()}>Cancel</Button>
        <Button onClick={() => saveFilterConfiguration(filterName, isPrivate)}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}


export default SavePresetFilterDialog
